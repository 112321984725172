<template>
  <form @submit="save()">
    <div class="form-group">
      <ProductVolume v-model="volume"></ProductVolume>
    </div>
    <div class="form-group">
      <label for>Cantidad</label>
      <!-- <app-paginate ref="pages" @change="quantity = $event"></app-paginate> -->
      <app-input-integer v-model="quantity"></app-input-integer>
    </div>
    <div class="form-group">
      <label for="">Codigo de Producto</label>
      <SelectorByCode @product="save($event)"></SelectorByCode>
    </div>
  </form>
</template>

<script>
import ProductVolume from "../../store-module/products/Volume";
import SelectorByCode from "../../store-module/products/SelectorByCode";
import { LogisticService } from "../service";

export default {
  components: {
    ProductVolume,
    SelectorByCode
  },
  data: () => ({
    volume: 1,
    quantity: 1
  }),
  props: {
    requirement_id: {}
  },
  methods: {
    save(product) {
      let oBody = {
        requirement_id: this.requirement_id,
        product_id: product.id,
        product_name: product.name,
        quantity:
          this.volume != 2
            ? this.quantity
            : this.quantity * product.units_by_pack
      };

      LogisticService.saveRequirementDetail(oBody).then((res) => {
        this.$emit("submitted", { id: res, ...oBody });
      });
    }
  },
  mounted() {
    // this.$refs.pages.setPageOptions({
    //   total: 100,
    //   current_page: 1,
    //   per_page: 1
    // });
  }
};
</script>

<style></style>
