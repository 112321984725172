<template>
  <div>
    <button
      v-for="(l, i) in categories"
      :key="i"
      class="btn btn-warning btn-sm mr-2 mb-2"
      @click="
        $refs.elQuest.val = l.id;
        $refs.elQuest.show();
      "
    >
      <span>{{ l.categorie_name }}</span>
      <i class="ml-1 fa fa-close"></i>
    </button>
    <button
      v-show="!disabled"
      class="btn btn-light btn-sm mr-2 mb-2"
      @click="$refs.elAdd.show()"
    >
      <i class="fa fa-plus"></i> Agregar Categoria
    </button>

    <app-modal-basic ref="elAdd">
      <h4>Agregar Categoria</h4>
      <select-categorie class="mb-2" v-model="categorie"></select-categorie>
      <app-button-submit @click="add()"></app-button-submit>
    </app-modal-basic>

    <app-modal-yn ref="elQuest" @yes="removeCategorie($refs.elQuest.val)">
      <p>Quitar esta categoria?</p>
    </app-modal-yn>
  </div>
</template>
<script>
import { LogisticService } from "../service";
import selectCategorie from "../../general-module/categories/InputSelect";
export default {
  components: {
    selectCategorie
  },

  // directives
  // filters

  props: {
    requirementId: {},
    disabled: { default: false }
  },

  data: () => ({
    categories: [],
    categorie: ""
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    this.getList();
  },

  methods: {
    removeCategorie(req_categorie_id) {
      LogisticService.deleteRequirementCategorie(req_categorie_id).then(() => {
        this.getList();
      });
    },
    add() {
      LogisticService.addRequirementCategorie({
        requirement_id: this.requirementId,
        categorie: this.categorie
      }).then(() => {
        this.getList();
      });
    },
    getList() {
      LogisticService.getRequirementCategories(this.requirementId).then(
        (list) => {
          this.categories = list;
        }
      );
    }
  }
};
</script>

<style></style>
