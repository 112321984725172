<template>
  <div class="btn-group">
    <div
      class="btn btn-secondary"
      tabindex="0"
      :class="{ active: value == 1 }"
      @click="$emit('input', 1)"
    >
      <i class></i> Unidad
    </div>
    <div
      class="btn btn-secondary"
      tabindex="0"
      :class="{ active: value == 2 }"
      @click="$emit('input', 2)"
    >
      <i class="fas fa-box"></i> Paquete
    </div>
  </div>
</template>

<script>
export default {
  props: { value: {} }
};
</script>

<style></style>
