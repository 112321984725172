<template>
  <div>
    <h4>REQUERIMIENTO {{ requirement_id }}</h4>

    <entity-head-info :entity_id="1" class="ml-1 mb-2 d-none d-print-flex">
    </entity-head-info>

    <div class="row">
      <div class="col-md-4 d-print-none">
        <div class="card card-body" v-show="status_id != 3">
          <FormCode
            @submitted="list.data.unshift($event)"
            ref="formCode"
            :requirement_id="requirement_id"
          ></FormCode>
        </div>
        <div class="form-group">
          <label for>Observaciones</label>
          <!-- <app-textarea v-model="observation" @blur="save()"></app-textarea> -->
          <app-comments :url="$route.path"></app-comments>
        </div>
        <app-button-submit
          v-show="status_id == 1"
          text="Solicitar"
          @click="
            status_id = 2;
            save();
          "
        ></app-button-submit>
        <button
          class="btn btn-light"
          @click="
            per_page = total;
            $refs.tr.gl();
          "
        >
          <i class="fa fa-eye"></i> Ver Completo
        </button>
        <button class="btn btn-light" @click="letsPrint()">
          <i class="fa fa-print"></i> Imprimir
        </button>
        <button class="btn btn-danger" @click="$refs.elShowPreDel.show()">
          <i class="fa fa-trash"></i> Eliminar marcados
        </button>
        <button class="btn btn-success" @click="getXlsx()">
          <i class="fa fa-file-excel-o"></i> Excel
        </button>
        <app-button-load
          v-show="status_id == 2"
          class="btn-light"
          text="Entregar"
          @click="proveer()"
        ></app-button-load>
        <categories class="mt-2" :requirementId="requirement_id"></categories>
        <router-link :to="`/logistic/requirements/${requirement_id}/quotation`"
          >Cotizacion</router-link
        >
      </div>
      <div class="col">
        <app-table-registers ref="tr" :getList="getList" :bordered="true">
          <template slot="top">
            <div class="mb-3 form-inline d-print-none">
              <app-button-submit
                @click="
                  $refs.mForm.show();
                  $refs.dForm.reset();
                "
              >
                Nuevo
              </app-button-submit>
              <app-button-load
                class="btn-light"
                text="Cargar productos Requeridos"
                @click="$refs.elQuestPrevLoadProd.show()"
              ></app-button-load>
              <app-input-search
                class="ml-auto"
                @search="
                  search = $event;
                  $refs.tr.gl();
                "
              ></app-input-search>
            </div>
          </template>
          <template slot="table">
            <thead>
              <tr>
                <th></th>
                <th v-show="status_id == 2">
                  <i class="fa fa-link"></i>
                </th>
                <th>PRODUCTO</th>
                <th>MARCA</th>
                <th>CATEGORIA</th>
                <th>CANTIDAD</th>
                <th class="d-print-none"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="l in list.data" :key="l.id">
                <td>
                  <app-checkbox v-model="l.to_delete">
                    <i class="fa fa-trash"></i>
                  </app-checkbox>
                </td>
                <td v-show="status_id == 2">
                  <app-checkbox v-model="l.to_get">
                    <i class="fa fa-envelope"></i>
                  </app-checkbox>
                </td>
                <td>
                  <div>{{ l.product_name }}</div>
                  <span class="badge badge-light" v-show="l.brand_name"> </span>
                </td>
                <td>{{ l.brand_name }}</td>
                <td>{{ l.categorie_name }}</td>
                <td class="text-center">
                  <app-span-input
                    :sm="true"
                    :value="l.quantity"
                    @input="
                      l.quantity = $event;
                      saveDetail(l);
                    "
                    >{{ l.quantity }}
                    <!-- <span v-show="l.stock">({{ l.stock }})</span> -->
                  </app-span-input>
                </td>
                <td class="d-print-none">
                  <button
                    class="btn btn-light btn-sm"
                    @click="
                      $refs.dDel.show();
                      $refs.dDel.val = l.id;
                    "
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>

    <app-modal-yn ref="elQuestPrevLoadProd" @yes="cargarProductos()">
      <p>Cargar productos requeridos? se perdaran algunos cambios</p>
    </app-modal-yn>

    <app-modal-basic ref="mForm">
      <FormDetail
        @submitted="addDetail($event)"
        :requirement_id="requirement_id"
        ref="dForm"
      ></FormDetail>
    </app-modal-basic>

    <app-modal-yn ref="elShowPreDel" @yes="deleteAllToDelete()">
      <p>Eliminar a todas las filas marcadas?</p>
    </app-modal-yn>

    <app-modal-yn
      ref="dDel"
      @yes="
        $refs.dDel.hide();
        deleteItem($refs.dDel.val);
      "
    >
      <p>Eliminar el Registro?</p>
    </app-modal-yn>
  </div>
</template>

<script>
import { LogisticService } from "../service";
import FormCode from "./FastCode";
import FormDetail from "./FormDetail";
import http from "src/lt/utils/http.js";
import entityHeadInfo from "../../admin-module/entitys/HeadInfo";
import categories from "./categories";
// import { saveAs } from "file-saver";
// import XLSX from "xlsx";

export default {
  components: { FormCode, FormDetail, entityHeadInfo, categories },
  props: {
    requirement_id: {}
  },
  mounted() {
    LogisticService.getRequirement(this.requirement_id).then((reg) => {
      this.observation = reg.observation;
      this.status_id = reg.status_id;
    });
  },
  data: () => ({
    list: {},
    observation: "",
    status_id: null,
    per_page: 15,
    total: 1,
    search: ""
  }),
  methods: {
    deleteAllToDelete() {
      LogisticService.deleteRequirementDetails(
        this.list.data
          .filter((x) => {
            return x.to_delete;
          })
          .map((x) => x.id)
      ).then(() => {
        this.$refs.tr.gl();
      });
    },
    getXlsx() {
      // var tbl = this.$refs.tr.getTableEl();
      // var wb = XLSX.utils.table_to_book(tbl);
      // var wopts = { bookType: "xlsx", bookSST: false, type: "binary" };
      // var wbout = XLSX.write(wb, wopts);
      // function s2ab(s) {
      //   var buf = new ArrayBuffer(s.length);
      //   var view = new Uint8Array(buf);
      //   for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      //   return buf;
      // }
      // saveAs(
      //   new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      //   "ordenDeCotizacion.xlsx"
      // );
    },
    letsPrint() {
      print();
    },
    proveer() {
      LogisticService.saveRequirement({
        id: this.requirement_id,
        observation: this.observation,
        letsProvide: 1
      }).then(
        () => {
          this.status_id = 3;
        },
        ({ response }) => {
          alert(response.data.message);
        }
      );
    },
    save() {
      LogisticService.saveRequirement({
        id: this.requirement_id,
        observation: this.observation,
        status_id: this.status_id
      });
    },
    cargarProductos() {
      http
        .post("api/logistic/requirements", {
          requirement_id: this.requirement_id,
          load_products: 1
        })
        .then(() => {
          this.$refs.tr.gl();
        });
    },
    deleteItem(id) {
      LogisticService.deleteRequirementDetail(id).then(() => {
        this.$refs.tr.gl();
      });
    },
    addDetail(detail) {
      let lUpdated = false;
      for (let x of this.list.data) {
        // console.log("try", x, detail);
        if (x.id == detail.id) {
          lUpdated = true;
          // console.log("try")
          x.quantity += parseFloat(detail.quantity);
        }
      }

      if (!lUpdated) {
        this.list.data.unshift(detail);
      }
    },
    saveDetail(detail) {
      LogisticService.saveRequirementDetail(detail);
    },
    getList(params) {
      return new Promise((rsv) => {
        LogisticService.getRequirementDetails({
          requirement_id: this.requirement_id,
          ...params,
          per_page: this.per_page,
          search: this.search || undefined
        }).then((res) => {
          this.per_page = res.per_page;
          this.total = res.total;
          this.list = res;
          this.list.data = res.data.map((x) => {
            x.to_delete = false;
            return x;
          });
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
